import React from "react";
import { graphql } from "gatsby";
import { Layout } from "~/templates/Layout";
import { ReportCard } from "~/molecules/ReportCard";
import { AwardCards } from "~/molecules/AwardCards";
import { ArticleListSection } from "~/molecules/ArticleListSection";
import { CallToActionButton } from "~/atoms/CallToActionButton";
import { PATH } from "~/constant/path";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import { siteWidth } from "~/utils/variables";
import TitleSvg from "~/images/awards/title.inline.svg";

interface Props {
  data: {
    award2023: GatsbyTypes.ContentfulAwardConnection;
    award2020: GatsbyTypes.ContentfulAwardConnection;
    award2017: GatsbyTypes.ContentfulReportConnection;
    award2018: GatsbyTypes.ContentfulReportConnection;
  };
}

const AwardListPage: React.FC<Props> = ({ data }) => {
  const breadCrumbItems = [PATH.TOP, PATH.EVENTS, PATH.EVENTS_AWARD];

  return (
    <>
      <style jsx>{`
        .buttonWrapper {
          display: flex;
          justify-content: center;
          margin-top: 30px;
          text-align: center;
        }
        .buttonWrapper :global(.callToActionButton a) {
          display: block;
          padding: 13px 30px;
          width: 340px;
        }
        .buttonWrapper :global(a.borderGradient):before {
          background-color: ${colors.creamGray};
        }
        .eyecatch {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 50px 0;
        }
        .eyecatchImage {
          display: block;
        }
        .container {
          display: flex;
          flex-direction: column;
          margin: 0 auto;
          max-width: ${siteWidth.default};
          padding: 0 15px;
        }
        .description {
          color: ${colors.defaultText};
          text-align: center;
          font-size: 16px;
          line-height: 1.75;
          margin: 20px 0 0;
        }
        .en {
          font-size: 1.16em;
        }
        .heading {
          color: ${colors.defaultText};
          font-size: 17px;
          margin: 30px 0 0;
          text-align: center;
        }
        .paragraph {
          color: ${colors.defaultText};
          font-size: 14px;
          margin: 20px 0 0;
          text-align: center;
        }
        .styledList {
          color: ${colors.defaultText};
          font-size: 14px;
          margin: 20px 0 0;
          text-align: center;
        }
        .awards {
          display: flex;
          flex-wrap: wrap;
          position: relative;
          width: 100%;
        }
        @media (${breakPoints.sp}) {
        }
      `}</style>
      <Layout
        title={PATH.EVENTS_AWARD.name}
        ogpDescription="Refcomeはリファラル採用の定着度・活発度を見える化し、データに基づいた改善案を導き出します。人事経験者や人材業界出身のアドバイザーが、850社のリファラル採用支援で蓄積したノウハウをもとに、組織の課題や状況に合わせてリファラル採用を成功に導く支援を行います。"
        breadCrumbItems={breadCrumbItems}
      >
        <div className="award">
          <div className="eyecatch">
            <img src={TitleSvg} />
          </div>
          <div className="content">
            <div className="container">
              <p className="description">
                株式会社リフカムが主催する「<span className="en">Referral Recruiting AWARD</span>」は、
                <br />
                当社のミッションである「採用を仲間集めに」を体現し、
                <br />
                リファラル採用に尽力している素晴らしい成果を残した企業を表彰します。
                <br />
                これは、リファラル採用の先進的な取り組みを紹介し、
                <br />
                すべての企業でリファラル採用がさらなる活性化をされることを目的としたものです。
                <br />
              </p>
              <h3 className="heading">表彰対象</h3>
              <p className="paragraph">リファラル採用に取り組んでいる企業を表彰対象とします。</p>
              <h3 className="heading">応募資格</h3>
              <ul className="styledList">
                <li>リファラル採用を実施している企業様で、採用に携わっている方</li>
                <li>インタビューを受けて頂ける方</li>
                <li>取り組みをオープンに公開いただける企業様</li>
              </ul>
              <h3 className="heading">審査基準</h3>
              <ul className="styledList">
                <li>リファラル採用が活性化されている点</li>
                <li>独自の工夫をしていて成果が出ている点</li>
                <li>リファラル採用が定着している点</li>
              </ul>
            </div>
          </div>
          {data.award2023.totalCount > 0 && (
            <ArticleListSection title={PATH.AWARD_2023.name}>
              <AwardCards awards={data.award2023} />
              <div className="buttonWrapper">
                <CallToActionButton to={PATH.AWARD_2023.to} backgroundColor={colors.creamGray}>
                  {PATH.AWARD_2023.name}一覧を見る
                </CallToActionButton>
              </div>
            </ArticleListSection>
          )}
          <ArticleListSection title={PATH.AWARD_2020.name}>
            <AwardCards awards={data.award2020} />
            <div className="buttonWrapper">
              <CallToActionButton to={PATH.AWARD_2020.to} backgroundColor={colors.creamGray}>
                {PATH.AWARD_2020.name}一覧を見る
              </CallToActionButton>
            </div>
          </ArticleListSection>
          <ArticleListSection title={PATH.EVENTS_AWARD_2018.name}>
            <ul className="awards">
              {data.award2018.edges.map((edge) => {
                const { node } = edge;

                return (
                  <ReportCard
                    key={`award2018_${node.contentful_id}`}
                    article={node}
                    href={`/events/${node.eventCategory?.slug}/interview${node.awardYear}/${node.contentful_id}`}
                  />
                );
              })}
            </ul>
            <div className="buttonWrapper">
              <CallToActionButton to={PATH.EVENTS_AWARD_2018.to} backgroundColor={colors.creamGray}>
                {PATH.EVENTS_AWARD_2018.name}一覧を見る
              </CallToActionButton>
            </div>
          </ArticleListSection>
          <ArticleListSection title={PATH.EVENTS_AWARD_2017.name}>
            <ul className="awards">
              {data.award2017.edges.map((edge) => {
                const { node } = edge;

                return (
                  <ReportCard
                    key={`award2017_${node.contentful_id}`}
                    article={node}
                    href={`/events/${node.eventCategory?.slug}/interview${node.awardYear}/${node.contentful_id}`}
                  />
                );
              })}
            </ul>
            <div className="buttonWrapper">
              <CallToActionButton to={PATH.EVENTS_AWARD_2017.to} backgroundColor={colors.creamGray}>
                {PATH.EVENTS_AWARD_2017.name}一覧を見る
              </CallToActionButton>
            </div>
          </ArticleListSection>
        </div>
      </Layout>
    </>
  );
};
export default AwardListPage;

export const query = graphql`
  query AwardListPage {
    award2023: allContentfulAward(
      filter: { eventYear: { eq: 2023 } }
      limit: 3
      sort: { fields: publishedAt, order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          contentful_id
          eventYear
          eyecatch {
            fluid {
              src
            }
          }
          title
          createdAt(fromNow: false)
          publishedAt(fromNow: false)
          company {
            name
            id
          }
        }
      }
    }
    award2020: allContentfulAward(
      filter: { eventYear: { eq: 2020 } }
      limit: 3
      sort: { fields: publishedAt, order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          contentful_id
          eventYear
          eyecatch {
            fluid {
              src
            }
          }
          title
          createdAt(fromNow: false)
          publishedAt(fromNow: false)
          company {
            name
            id
          }
        }
      }
    }
    award2018: allContentfulReport(
      filter: { eventCategory: { slug: { eq: "award" } }, awardYear: { eq: 2018 } }
      limit: 3
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          date
          awardYear
          companyName
          ogpImage {
            fluid(maxWidth: 767) {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
          title
          contentful_id
          eventCategory {
            slug
          }
        }
      }
    }
    award2017: allContentfulReport(
      filter: { eventCategory: { slug: { eq: "award" } }, awardYear: { eq: 2017 } }
      limit: 3
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          date
          awardYear
          companyName
          ogpImage {
            fluid(maxWidth: 767) {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
          title
          contentful_id
          eventCategory {
            slug
          }
        }
      }
    }
  }
`;
