import React from "react";
import { Container } from "~/atoms/Container";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

interface Props {
  children: any;
  title: string;
}

export const ArticleListSection: React.FC<Props> = ({ children, title }) => {
  return (
    <>
      <style jsx>{`
        .articleListSection {
          background: ${colors.bgGrayGradient};
          padding: 0 30px;
        }

        .articleListSection :global(.container) {
          flex-direction: column;
          padding: 70px 0;
        }

        .articleListSection_titleContainer {
          display: flex;
          justify-content: center;
          margin: 0 0 20px;
        }

        .articleListSection_title {
          border-bottom: solid 2px ${colors.brand};
          font-size: 24px;
          text-align: center;
        }

        @media (${breakPoints.sp}) {
          .articleListSection {
            background: initial;
            background-color: ${colors.creamGray};
            padding: 0 20px;
          }

          .articleListSection :global(.container) {
            padding: 20px 0;
          }

          .articleListSection_title {
            font-size: 21px;
          }
        }
      `}</style>
      <div className="articleListSection">
        <Container>
          <div className="articleListSection_titleContainer">
            <h2 className="articleListSection_title">{title}</h2>
          </div>
          {children}
        </Container>
      </div>
    </>
  );
};
